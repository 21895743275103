<template>
    <a-alert :type="isUnique ? 'success' : 'warning'" :message="message" />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { compareTwoStrings } from 'string-similarity';

import { Watch } from '@/utils/decorators';
import { debounce } from '@/utils/helpers';

import { AAlert } from '@/components/AAlert';

const ValidateSimilarityProps = Vue.extend({
    name: 'ValidateSimilarity',
    props: {
        source: {
            type: null as unknown as PropType<string | null>,
            default(): string {
                return '';
            }
        },
        target: {
            type: null as unknown as PropType<string | null>,
            default(): string {
                return '';
            }
        },
        message: {
            type: String,
            default() {
                return 'Fields should be unique';
            }
        },
        delay: {
            type: [Number, String],
            default() {
                return 1000;
            }
        },
        threshold: {
            type: [Number, String],
            default() {
                return 75;
            }
        }
    }
});

@Component({
    components: {
        AAlert
    }
})
export default class ValidateSimilarity extends ValidateSimilarityProps {
    isUnique = true;

    isMounted = false;

    @Watch('source')
    @Watch('target')
    onPropsChange() {
        if (this.isMounted && this.source && this.target) {
            this.debouncedValidate();
        }
    }

    debouncedValidate = debounce(this.validate, Number(this.delay));

    mounted() {
        this.isMounted = true;
    }

    async validate() {
        if (this.source && this.target) {
            const similarity = Number(
                (compareTwoStrings(this.source, this.target) * 100).toFixed()
            );

            this.isUnique = similarity < Number(this.threshold);
        } else {
            this.isUnique = true;
        }
    }
}
</script>
