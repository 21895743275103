<template>
    <v-container class="amp-module-page">
        <a-form ref="form" auto-submit @auto-submit="setData">
            <amp-row-first>
                <template #input>
                    <a-alert
                        v-for="(issue, i) in publishIssues"
                        :key="i"
                        :message="issue.message"
                    />
                    <a-text-input
                        ref="headline"
                        v-model="blogpost.headline"
                        label="Headline"
                        rules="required|max:80"
                        rows="1"
                        textarea
                        auto-grow
                        observed
                        hint="Choose a headline for blogs that also publish your amp. This should not be the same as the news article headline."
                    />
                </template>

                <template #validation>
                    <amp-validation
                        ref="headlineValidation"
                        :input="$refs.headline"
                    >
                        <a-alert :type="blogpost.headline ? 'success' : 'info'">
                            <span>
                                Headline must be fewer than 80 characters.
                                Current:
                                {{
                                    blogpost.headline
                                        ? characterCount(blogpost.headline)
                                        : 0
                                }}
                            </span>
                        </a-alert>

                        <validate-similarity
                            :source="blogpost.headline"
                            :target="newsArticleHeadline"
                            message="Headline should be different from the News Article headline"
                        />
                    </amp-validation>
                </template>
            </amp-row-first>

            <amp-row>
                <template #input>
                    <a-text-input
                        ref="summary"
                        v-model="blogpost.summary"
                        label="Summary"
                        rules="required|words:0,45"
                        textarea
                        rows="4"
                        auto-grow
                        observed
                        hint="Choose a summary for use on blogs that publish your amp. This must not be the same as the news article summary."
                    />
                </template>

                <template #validation>
                    <amp-validation :input="$refs.summary">
                        <a-alert
                            :type="blogpost.summary ? 'success' : 'info'"
                            :message="
                                actualWordsCount(
                                    blogpost.summary,
                                    0,
                                    45,
                                    'Summary'
                                )
                            "
                        />

                        <validate-similarity
                            :source="blogpost.summary"
                            :target="newsArticleSummary"
                            message="Summary should be different from the News Article summary"
                        />
                    </amp-validation>
                </template>
            </amp-row>

            <amp-row>
                <template #input>
                    <label class="mb-1">Media</label>

                    <div class="pb-8">
                        <media-preview-list
                            :media-resources="blogpost.media_resources"
                            :user-id="blogpost.user_id"
                            class="pa-1"
                            module-title="Blog Post"
                            @mediaFiles="onMediaFilesUpdate"
                        />

                        <validation-provider
                            ref="media_resources"
                            name="media_resources"
                            slim
                        >
                            <input
                                v-model="blogpost.media_resources"
                                type="hidden"
                            />
                        </validation-provider>
                    </div>
                </template>
            </amp-row>

            <amp-row-last>
                <template #input>
                    <a-content-editor
                        v-model="blogpost.content"
                        label="Content"
                        rules="required"
                        observed
                        :no-first-person="false"
                        :formats="formats"
                        :toolbar="toolbar"
                        :add-video-placeholder="addVideoPlaceholder"
                    />
                </template>

                <template #validation>
                    <a-alert v-if="hasMSNCredits" type="info">
                        To maintain uniformity, uphold high-quality standards,
                        and ensure compliance with MSN criteria; please refer to
                        the following
                        <router-link to="/guidelines/msn" target="_blank">
                            <b>guidelines</b>
                        </router-link>
                        while creating your blog posts.
                    </a-alert>

                    <content-editor-validations
                        :content="blogpost.content"
                        rules="wordCountBetween:300,3000|linkCount:html"
                    />
                </template>
            </amp-row-last>
        </a-form>
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { NavigationGuardNext, Route } from 'vue-router';
import { ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';

import { Endpoint, WordsCounter } from '@/mixins';
import { InjectReactive, Watch } from '@/utils/decorators';

import { AAlert } from '@/components/AAlert';
import { AForm } from '@/components/AForm';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';
import { AContentEditor } from '@/components/AForm/Inputs/AContentEditor';
import { ContentEditorValidations } from '@/components/ContentEditor';
import { MediaPreviewList } from '@/components/Media';
import { ValidateSimilarity } from '@/components/ValidateSimilarity';

import {
    AmpRow,
    AmpRowFirst,
    AmpRowLast,
    AmpValidation
} from '@/components/AmpModule/AmpPage';

import type { AmpModules } from '@/types/Announcement';
import type { BlogPost as IBlogPost } from '@/types/BlogPost';
import type { FormMediaResource } from '@/types/Media';
import type { ModuleLink } from '@/types/ModuleLink';
import { wordCount } from '@/utils/helpers';

Component.registerHooks(['beforeRouteLeave']);

@Component({
    components: {
        AForm,
        ATextInput,
        AmpRow,
        AmpRowFirst,
        AmpRowLast,
        AmpValidation,
        MediaPreviewList,
        AContentEditor,
        AAlert,
        ContentEditorValidations,
        ValidateSimilarity
    },
    computed: {
        ...mapGetters('user', ['isEditor'])
    }
})
export default class BlogPost extends mixins(Endpoint, WordsCounter) {
    hasMSNCredits = false;

    isEditor!: boolean;

    $refs!: {
        form: InstanceType<typeof AForm>;
        headline: InstanceType<typeof ATextInput>;
        summary: InstanceType<typeof ATextInput>;
        media_resources: InstanceType<typeof ValidationProvider>;
    };

    @InjectReactive({
        from: 'modules',
        default() {
            return null;
        }
    })
    modules!: AmpModules;

    @Watch('modules')
    onModulesChanged() {
        this.onMounted();
    }

    @Watch('blogpost.media_resources', { deep: true })
    onMediaResourcesChange() {
        if (this.isReadyForChanges) {
            this.$refs.media_resources.setFlags({
                dirty: true,
                changed: true,
                touched: true
            });
        }
    }

    blogpost: Partial<IBlogPost> = {
        media_resources: []
    };

    isSaving = false;
    isReadyForChanges = false;

    endpoint = '/blog_posts/edit';

    link: ModuleLink[] = [
        {
            type: 'primary',
            label: 'Review',
            to: this.reviewLink
        }
    ];

    formats: Array<string> = [
        'link',
        'firstPersonError',
        'bold',
        'header',
        'italic',
        'underline',
        'list',
        'videoPlaceholder'
    ];

    toolbar: Array<Array<string | object>> = [
        ['link'],
        ['bold', 'italic', 'underline'],
        [{ header: [0, 1, 2, 3, 4, 5, 6] }],
        [{ list: 'ordered' }, { list: 'bullet' }]
    ];

    addVideoPlaceholder = false;

    get moduleId() {
        return this.modules?.blog_post_id;
    }

    get announcementId() {
        return this.$route.params.announcementId;
    }

    get sourceUrl() {
        return [this.endpoint, this.moduleId].join('/');
    }

    get newsArticleHeadline() {
        return this.blogpost.announcement?.press_release.headline;
    }

    get newsArticleSummary() {
        return this.blogpost.announcement?.press_release.summary;
    }

    get publishIssues() {
        return this.$store.getters['broadcast/subscribe'](
            `${this.announcementId}-publish-blog-post`
        );
    }

    get canCreateAsyncPodcast() {
        const { announcement } = this.blogpost;

        return (
            this.isEditor &&
            !this.modules.podcast_id &&
            this.blogpost.user?.has_ai_features &&
            !!announcement?.author_request_assignment &&
            announcement?.supporting_urls.length &&
            wordCount(this.blogpost.content || '') >= 300
        );
    }

    get reviewLink() {
        return `/announcements/review/${this.announcementId}/blog-post`;
    }

    onMounted() {
        if (this.moduleId) {
            this.setPrePublishHook();

            this.load();
        } else {
            this.askToCreate();
        }
    }

    setPrePublishHook(isSet = true) {
        this.$emit('pre-publish', isSet ? this.prePublish.bind(this) : null);
    }

    askToCreate() {
        if (this.modules && !this.moduleId) {
            this.$emit('create', this.endpoint);
        }
    }

    onData(data: { blogPost: IBlogPost; hasMSNCredits: boolean }) {
        if (data.blogPost) {
            this.blogpost = data.blogPost;

            this.setVideoPlaceholder();

            this.hasMSNCredits = data.hasMSNCredits;

            this.protectRoute();

            this.emitLinks();

            return this.commit();
        } else {
            this.review();
        }
    }

    commit() {
        return this.setSaved().then(() => {
            this.isReadyForChanges = true;
        });
    }

    getDataToSave() {
        return {
            id: this.blogpost.id,
            headline: this.blogpost.headline,
            summary: this.blogpost.summary,
            media_resources: this.blogpost.media_resources,
            content: this.checkForVideoPlaceholder(this.blogpost.content)
        };
    }

    checkForVideoPlaceholder(content: string | undefined): string {
        if (content === undefined) {
            return '';
        }

        if (content.includes('{video_pr:link}')) {
            return content.replace(/<\s*img(\s+.*?>|>)/gi, '{video_pr:link}');
        }

        return content;
    }

    save(foreground = true) {
        this.setSaving();

        return this.setData()
            .then(async data => {
                if (!data.meta.success && foreground) {
                    this.notifyError();

                    return false;
                }

                await this.setSaved();

                if (foreground) {
                    this.onSave();
                }
            })
            .finally(this.setSaving.bind(this, false));
    }

    setSaving(isSaving = true) {
        this.isSaving = isSaving;
    }

    async setSaved() {
        return this.$refs.form.reset();
    }

    setData() {
        return this.$http
            .post(this.sourceUrl, this.getDataToSave())
            .then(({ data }) => {
                // update local instance with computed props, if any
                if (data.blogPost) {
                    this.blogpost = data.blogPost;
                }

                return data;
            })
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }

                return {};
            });
    }

    async onSave() {
        const isValid = await this.revalidate();

        if (isValid) {
            this.review();
        }
    }

    async revalidate() {
        // reset existing errors
        this.$store.dispatch(
            'broadcast/reset',
            `${this.announcementId}-publish-blog-post`
        );

        const isValid = await this.$refs.form.validate();

        if (!isValid) {
            this.notifyInvalid();
        }

        return isValid;
    }

    review() {
        this.$router.push(this.reviewLink);
    }

    beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
        this.setPrePublishHook(false);

        if (this.canCreateAsyncPodcast) {
            this.createAsyncPodcast();
        }

        if (this.$refs.form.isDirty) {
            return this.save(false).then(() => {
                next();
            });
        } else {
            return next();
        }
    }

    protectRoute() {
        if (!this.blogpost.is_editable) {
            this.review();
        }
    }

    emitLinks() {
        this.$emit('links', this.link);
    }

    prePublish() {
        if (!this.$refs.form?.isDirty) {
            return this.revalidate();
        }

        this.setSaving();

        return this.setData()
            .then(async data => {
                if (data.meta.success) {
                    return this.revalidate();
                } else {
                    this.notifyError();

                    return false;
                }
            })
            .finally(this.setSaving.bind(this, false));
    }

    notifyInvalid() {
        this.$store.dispatch(
            'notification/info',
            'Blog Post saved successfully. Please check the form for errors.'
        );
    }

    notifyError() {
        this.$store.dispatch(
            'notification/error',
            'Unable to save Blog Post. Please check the form for errors.'
        );
    }

    onMediaFilesUpdate(resources: FormMediaResource[]) {
        this.blogpost.media_resources = resources;
    }

    createAsyncPodcast() {
        this.$http.post('/podcasts/create', {
            announcement_id: this.announcementId
        });
    }

    setVideoPlaceholder() {
        const content = this.blogpost.content;

        if (content === undefined) {
            return;
        }

        const hasVideoPlaceholder = content.includes('{video_pr:link}');
        if (hasVideoPlaceholder) {
            this.blogpost.content = content.replace(
                /{video_pr:link}/gi,
                '<img>'
            );
        }

        if (!hasVideoPlaceholder && this.blogpost.user?.has_ai_features) {
            const videoPlaceholder = `<p><img></br></p>`;
            this.blogpost.content = `${videoPlaceholder} ${this.blogpost.content}`;
        }
    }
}
</script>
